.body {
  height: 100%;
  padding: 0 20%;
  display: flex;
}
.body .divider {
  width: 50%;
}
.body .absDivider {
  /* position: absolute; */
  height: 100%;
}
.wrapper {
  background: rgb(15 23 42/1);
  min-height: 100vh;
}

.header {
  display: flex;
  width: 100%;
  height: 20%;
  display: flex;
  flex: auto;
  /* padding: 10%; */
  font-size: 38px;
  background: #936e4e;
}
.header .text {
  padding: 5% 5%;
}
.body .leftHeader {
  font-size: 20px;
  color: #e2e8f0;
  padding-top: 70px;
}
.body .leftHeader .name {
  font-size: 40px;
}
.body .leftHeader .desc {
  font-size: 18px;
  margin-top: 10px;
}
.body .leftHeader .desc1 {
  font-size: 18px;
  margin-top: 10px;
}
.body .leftHeader .desc .timeframe {
  font-size: 15px;
  margin-right: 10px;
  color: #d3d3d3;
}
.manchester {
  /* cursor: url(http://www.javascriptkit.com/dhtmltutors/cursor-hand.gif), auto; */

  cursor: url("../../public/redDevil.png"), auto;
}
.icons {
  position: fixed;
  bottom: 10%;
}
.leftHeaderFixed {
  position: fixed;
  top: 0;
}
@media only screen and (max-width: 1450px) {
  .leftHeaderFixed {
    position: inherit;
  }
  .body {
    display: block;
    padding: 0 15%;
  }
  .icons {
    left: 30px;
  }
  .divider {
    width: 100% !important;
  }
}
.icons img {
  height: 25px;
  width: 25px;
  margin-right: 10px;
  cursor: pointer;
}
.desc2 {
  padding-bottom: 10px;
}

/* @keyframes fadein {
  0% {
    opacity: 1;
  }
  66% {
    opacity: 0.95;
  }
  100% {
    opacity: 0.9;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 1;
  }
  66% {
    opacity: 0.95;
  }
  100% {
    opacity: 0.9;
  }
} */
